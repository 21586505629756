import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumbs from "../comon/breadcrumbs";
import Services from "../components/services";
import Loading from "../comon/loading";
import Errors from "../comon/error";
import { Link, useParams, useNavigate } from "react-router-dom";

function ServicePage() {
    const API_URI = "https://admin.massholdings.com.np/api/home";
    const { slug } = useParams();
    const [Data, setData] = useState([]);
    console.log('Home', Data)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
        (async () => {
          try {
            setLoading(true);
            setError(false);
            const response = await axios.get(API_URI);
            setData(response.data);
            setLoading(false);
            
          } catch (error) {
            setError(true);
            setLoading(false);
          }
        })();
      }, [slug]);
    
      if (loading) return <Loading />;
      if (error) return <Errors />;
    
    return(
        <>
            <Breadcrumbs/>
            <div className="container">
                <Services services={Data.services ? Data.services : []}/>
            </div>
        </>
    )
}

export default ServicePage;