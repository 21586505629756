import React from "react";
import Slider from "react-slick";
import logo from "../assets/img/logo.png";

function Brands({brand}) {

    var Bsettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return(
        <div className="MainBrand">
        <div className="container">
          <div className="STitle">
            <h1>Our Brands</h1>
          </div>
          <div className="BItems">
            <Slider {...Bsettings}>
              {brand
                ? brand.map((brand) => (
                    <>
                      <div key={brand.id} className="BIMGS">
                        <img
                          src={brand?.DocPath ? brand?.DocPath : logo}
                          alt={brand.name}
                        />
                      </div>
                    </>
                  ))
                : ""}
            </Slider>
          </div>
        </div>
      </div>
    )
}

export default Brands;