import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../assets/img/logo.png'

function ItemCard({item}) {
    const navigate = useNavigate();
    
    return(
        <>
            <Link key={item.id}
                className="TItemsBox"
                to={`../products/details/${item.slug}`}
            >
                <div className="CIMG">
                <img src={item.DocPath?item.DocPath:Logo} alt={item.item_name} />
                </div>
                <div className="CTitle">{item.item_name}</div>
            </Link>
        </>
    )
}

export default ItemCard;