import React from "react";

function Services({services}) {
    return( 
        <div className="row servicesss justify-content-center">
            {services
            ? services.map((service) => (
                <div key={service.id} className="col-sm-12 col-md-7 col-lg-4">
                    <div className="SBox">
                    <div>
                        <img
                        src={service.DocPath}
                        alt={service.title}
                        height="100"
                        ></img>
                        <h2>{service.title}</h2>

                        <p
                        dangerouslySetInnerHTML={{
                            __html: service.description,
                        }}
                        ></p>
                    </div>
                    </div>
                </div>
                ))
            : "No Data"}
        </div>
    )
}

export default Services