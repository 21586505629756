import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import Loading from "../comon/loading";
import Errors from "../comon/error";
import { TabTitle } from "../comon/dynamicTitle";
import ItemCard from "../components/itemCard";
import '../assets/css/product.css';
import Breadcrumbs from "../comon/breadcrumbs";
import ReactPaginate from "react-paginate";

const ListPage = () => {
  const { slug } = useParams();
  TabTitle(`Mass Holdings | ${slug}`);
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 6

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          `https://admin.massholdings.com.np/api/products/all/${slug}/${page}`
        );
        setData(response.data.items);
        setTotalPages(Math.ceil(response.data.items.length / itemsPerPage));
        
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);

  if (loading) return <Loading />;
  if (error) return <Errors />;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const subset = Data.slice(startIndex, endIndex);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  
  return(
      <>
          <Breadcrumbs />
          <div className="container">
            <div className="parent-list-items">
              {subset.length > 0 
              ? subset?.map((item) => (
                <div className="col-lg-4 col-md-6 col-12 p-4">
                  <ItemCard key={item.id} item={item}/>
                </div>
              ))
              :"No Products Found!"}
            </div>  
          </div> 
        {
          itemsPerPage >= 6 ? 
          <div className="container">
          <div className="pagination">
            <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageChange}
                forcePage={currentPage}
            /> 
          </div>
        </div> 
        : ''
        }
         
      </>
  )
}

export default ListPage;