import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import Loading from "../comon/loading";
import Errors from "../comon/error";
import logo from "../assets/img/logo.png";
import { Link, useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import Brands from "../components/brand";
import "../assets/css/style.css"
const About = (props) => {
  const { slug } = useParams();
  const content = props.content;
  const breadcrum = props.breadcrum;
  console.log("----Params----", content);

  const API_URI = "https://admin.massholdings.com.np/api/home";
  const [Data, setData] = useState([]);
  console.log('Home', Data)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(API_URI);
        setData(response.data);
        setLoading(false);
        
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);

  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <div className="about-page-section">
        <div className="container">
          {content?.length === 0
            ? "No Content"
            : content?.map((content) => (
                <>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 p-md-5">
                      <div className="CContent">
                        <h1>{content.title}</h1>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: content.Description
                              ? content.Description
                              : "No Content",
                          }}
                        ></p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 p-md-5">
                      <div className="CImages">
                        <img
                          src={content.CoverImage ? content.CoverImage : logo}
                          alt={content.title}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
        </div>
      <Brands brand={Data.brand}/>
      </div>
    </>
  );
};
export default About;
